<template>
  <v-form data-cy-form @submit="onSubmit">
    <slot />
  </v-form>
</template>

<script lang="ts" setup>
import { useForm } from "vee-validate";

const emit = defineEmits<{
  (event: "submit", payload: Record<string, unknown>): void;
}>();

const { handleSubmit } = useForm();

const onSubmit = handleSubmit(
  (form) => emit("submit", form),
  (form) => {
    const firstInvalidFieldName = Object.entries(form.errors)[0]?.[0];

    const element = document.querySelector<HTMLElement>(
      `[data-field-name="${firstInvalidFieldName}"]`,
    );
    if (!element) return;

    const position = element.getBoundingClientRect();

    scrollTo({
      top: position.top + (document.scrollingElement?.scrollTop ?? 0) - 100,
      behavior: "smooth",
    });
  },
);
</script>
